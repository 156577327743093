import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import * as api from '../../../api';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { setAlert, setLoading, setVehiclePreviewPage } from '../../../globalState/action-creators';
import './media.css';
import Select from '../../../components/bootstrap/forms/Select';
import { HoldVehicleType } from '../../../constants';
import { AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import DatePicker from 'react-date-picker';
import moment from 'moment';

const AddHoldVehicle = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const today = new Date();

	const [nameError, setNameError] = useState(false);
	const [licensePlateError, setLicensePlateError] = useState(false);
	const [makeError, setMakeError] = useState(false);
	const [modelError, setModelError] = useState(false);
	const [vehicleTypeError, setVehicleTypeError] = useState(false);
	const [dropdownSelected, setDropdownSelected] = useState(false);
	const [selectNameError, setSelectNameError] = useState(false);

	const [searchData, setSearchData] = useState({
		total: 0,
		name: [],
	});

	const [formData, setFormData] = useState({
		name: '',
		licensePlate: '',
		make: '',
		model: '',
		vehicleType: '',
		guestPermitExpiration: '',
	});

	const onChange = (e) => {
		const { name, value } = e.target;
		if (name === 'name') {
			setNameError(false);
		}
		if (name === 'licensePlate') {
			setLicensePlateError(false);
		}
		if (name === 'make') {
			setMakeError(false);
		}
		if (name === 'model') {
			setModelError(false);
		}
		if (name === 'vehicleType') {
			setVehicleTypeError(false);
		}

		if (name === 'vehicleType' && value === 'HouseHold') {
			setFormData({ ...formData, [name]: value, guestPermitExpiration: '' });
		} else {
			setFormData({ ...formData, [name]: value });
		}
	};

	const createVehicle = async () => {
		dispatch(setLoading(true));
		try {
			let error;
			if (selectNameError) {
				error = true;
			}
			if (_.get(formData, 'name', '')?.length <= 0) {
				error = true;
				setNameError(true);
			}
			if (
				_.get(formData, 'licensePlate', '')?.length <= 0
				// !reDigit.test(_.get(formData, 'licensePlate', ''))
			) {
				error = true;
				setLicensePlateError(true);
			}
			if (_.get(formData, 'make', '')?.length <= 0) {
				error = true;
				setMakeError(true);
			}
			if (_.get(formData, 'model', '')?.length <= 0) {
				error = true;
				setModelError(true);
			}
			if (_.get(formData, 'vehicleType', '')?.length <= 0) {
				error = true;
				setVehicleTypeError(true);
			}
			if (!error) {
				const { data } = await api.createVehicle(formData);
				if (data.success) {
					dispatch(setVehiclePreviewPage({ vehiclePage: 1 }));
					navigate('/vehicles');
					dispatch(setAlert(data.message, 'Success'));
				} else {
					dispatch(setAlert(data.message, 'Error'));
				}
			}
		} catch (error) {
			dispatch(setAlert(error.message, 'Error'));
		}
		dispatch(setLoading(false));
	};

	const handleSearch = async (name) => {
		try {
			const { data } = await api.searchNames(name);
			if (data.success) {
				setSearchData({
					total: data.total || 0,
					name: data?.data ? data?.data : [],
				});
			}
		} catch (error) {
			setAlert(error.message, 'error');
		}
	};

	const handleAutoCompleteInput = (value) => {
		setTimeout(() => {
			handleSearch(value);
		}, 600);
	};

	const handleAutoCompleteSelect = (value) => {
		setDropdownSelected(true);
		setSelectNameError(false);
		setFormData({
			...formData,
			name: value,
		});
	};

	const handleAutoCompleteChange = (value) => {
		setFormData({
			...formData,
			name: value,
		});
		setNameError(false);
		setDropdownSelected(false);
		setSelectNameError(false);
	};

	return (
		<PageWrapper title='Add Vehicle'>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-xxl-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Plus' iconColor='dark'>
									<CardTitle tag='h4' className='h5'>
										Create Vehicle
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='create_vehicle_inner'>
										<div className='create-vehicle-wrapper add_vehicle'>
											{/* <div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<AutoComplete
														onSearch={handleAutoCompleteInput}
														className='w-100 custom_class'
														value={formData?.name}
														onSelect={handleAutoCompleteSelect}
														onChange={handleAutoCompleteChange}
														// onChange={() => {
														// 	setDropdownSelected(false);
														// }}
														onBlur={() => {
															!dropdownSelected &&
																setSelectNameError(true);
														}}
													>
														{searchData &&
															searchData?.name?.map((item) => (
																<AutoComplete.Option
																	key={item?._id}
																	value={item?.name}
																>
																	{item?.name}
																</AutoComplete.Option>
															))}
													</AutoComplete>
												</div>
												<span
													id='yearOfMakeSpan'
													className='text-danger danger-msg'
												>
													{nameError
														? 'Please enter a name'
														: !nameError &&
														  !dropdownSelected &&
														  selectNameError &&
														  formData?.name?.length > 0 &&
														  'Please select a name from options!'}
												</span>
											</div> */}

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Name{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														name='name'
														onChange={(e) => {
															onChange(e);
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{nameError && 'Please enter a name!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														License Plate{' '}
														<span className='text-danger'>*</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														id='licensePlates'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='licensePlate'
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															const key = e.key;
															const isSpecialCharacter =
																/[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/;

															if (
																key === ' ' ||
																(isSpecialCharacter.test(key) &&
																	key !== '-')
															) {
																e.preventDefault();
															}
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesSpan'
													className='text-danger danger-msg'
												>
													{licensePlateError &&
														'License Plate Number required!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Make{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect'
														autoComplete='off'
														name='make'
														onChange={(e) => {
															onChange(e);
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{makeError && 'Please enter a make!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Type
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<Select
														ariaLabel='Default select example'
														placeholder=' '
														style={{
															boxShadow:
																'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
														}}
														id='vehicleType'
														name='vehicleType'
														// value={formData?.VehicleType}
														onChange={(e) => onChange(e)}
														list={HoldVehicleType}
														className='addVehicleSelect inputBoxShadow'
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{vehicleTypeError &&
														'Please select vehicle type!'}
												</span>
											</div>

											<div className='card-info-item card-inner'>
												<div className='label'>
													<p className='mx-3 lable_spacing'>
														Model{' '}
														<span className='text-danger fw-bold'>
															*
														</span>
													</p>
												</div>
												<div className='data'>
													<input
														type='text'
														className='form-control addVehicleSelect no-arrows'
														autoComplete='off'
														name='model'
														onChange={(e) => {
															onChange(e);
														}}
														onKeyDown={(e) => {
															if (e.key === ' ') {
																e.preventDefault();
															}
														}}
														style={styles.inputStyles}
													/>
												</div>
												<span
													id='licensePlatesStateSpan'
													className='text-danger danger-msg'
												>
													{modelError && 'Please enter a model!'}
												</span>
											</div>

											{formData?.vehicleType === 'Guest' && (
												<div className='card-info-item card-inner'>
													<div className='label'>
														<p className='mx-3 lable_spacing'>
															Guest Permit Expiration{' '}
														</p>
													</div>
													<div className='data'>
														<DatePicker
															className='form-control addVehicleSelect input_feild'
															clearIcon={null}
															name='guestPermitExpiration'
															onChange={(e) => {
																const formattedDate = e
																	? moment(e).format('MM/DD/yyyy')
																	: '';
																setFormData({
																	...formData,
																	guestPermitExpiration:
																		formattedDate,
																});
															}}
															format='MM/dd/yyyy'
															value={
																formData?.guestPermitExpiration &&
																formData?.guestPermitExpiration
																	?.length > 0
																	? new Date(
																			formData?.guestPermitExpiration,
																	  )
																	: ''
															}
															minDate={today}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								<Button className='mx-2 mt-3' color='dark' onClick={createVehicle}>
									Save
								</Button>
								<Button
									className='mt-3'
									color='danger'
									onClick={() => navigate('/vehicles')}
								>
									Cancel
								</Button>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

const styles = {
	dateInput: {
		boxShadow: 'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
		lineHeight: '1.5',
		padding: '0.5rem 3rem 0.5rem 1rem',
		height: '3.5rem',
		color: 'transparent',
	},
	onChangeColor: '#323232',
	inputStyles: {
		boxShadow: 'rgb(116 116 116 / 53%) 0px 0px 1px 1px inset',
		lineHeight: '1.5',
		padding: '0.5rem 3rem 0.5rem 1rem',
		height: '3.5rem',
	},
};

export default AddHoldVehicle;
